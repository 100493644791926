<template>
  <div>
    <base-layout class="bg-gray-100">
      <div class="full-mode ">
        <div class="flex">
          <div class="px-4 pt-10 bg-white min-w-max w-1/5">
            <content-section
              class="border border-gray-400 override bg-white"
            >
              <oto-page-title title="Settings" class="inline-block mb-6" />
              <div class="mb-3 ">
                <settings-nav />
              </div>
            </content-section>
          </div>
          <div
            class="w-4/5 pr-16 bg-white pl-main override-2  overflow-y-auto rounded-md"
            style="height: calc(100vh - 55px - 150px )"
          >
            <div class="pt-8 pb-8 ">
              <router-view />
            </div>
          </div>
        </div>
      </div>
      <div class="responsive-mode">
        <div class="w-full bg-gray-100 overflow-y-auto" style="height: 100vh">
          <div class="sticky top-0  py-3  z-70 px-2">
            <oto-page-title title="Settings" class="inline-block mb-2 " />
            <t-rich-select
              :options="tabs"
              valueAttribute="href"
              textAttribute="text"
              :hideSearchBox="true"
              @change="changeRoute"
              v-model="routeValue"
            />
          </div>

          <div class="px-5 bg-gray-100 py-5">
            <router-view />
          </div>
        </div>
      </div>
    </base-layout>
    <section>
      <Footer />
    </section>
  </div>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout'
import ContentSection from '@/components/layout/ContentSection'
import OtoPageTitle from '@/components/ui/OtoPageTitle'
import SettingsNav from '@/composites/settings/SettingsNav'
import Footer from '@/composites/marketplace-buyer/footer/Footer.vue'

export default {
  name: 'Settings',
  components: {
    BaseLayout,
    ContentSection,
    OtoPageTitle,
    SettingsNav,
    Footer,
  },
  data() {
    return {
      tabs: [
        {
          text: 'Profile',
          href: this.getTabHref(`profile`),
        },
        {
          text: 'Payments',
          href: this.getTabHref(`payment`),
        },
        {
          text: 'Billing',
          href: this.getTabHref(`billing`),
        },
        {
          text: 'Bank',
          href: this.getTabHref(`bank`),
        },
      ],
      routeValue: '/settings/profile',
    }
  },
  mounted() {
    this.routeValue = this.$router.currentRoute.path ?? '/settings/profile'
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    getTabHref(path) {
      return `/settings/${path}`
    },
    changeRoute(event) {
      this.$router.push({ path: event })
    },
  },
  created: () => {
    this.$router.push({ path: '/settings/rider' })
  },
}
</script>

<style lang="scss" scoped>
.pl-main {
  padding-left: 4rem;
}
::v-deep .override {
  padding-left: 20px;
  padding-right: 20px;
  // width: 200px;
  nav {
    display: block !important;
    a {
      display: block !important;
      text-align: left;
      margin: 0;
    }
  }
}
::v-deep .override-2 {
  h2 {
    font-size: 22px;
  }
}
</style>
