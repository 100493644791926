<template>
  <div class="w-full bg-gray-50 py-5">
    <ul class="p-0 w-full list-none">
      <li class="h-12 text-gray-800" v-for="(item, i) in items" :key="i">
        <router-link
          :to="item.link"
          class="flex items-center px-4 h-full rounded-lg"
          @click="updateClass(item.link)"
        >
          <div class="mr-2 w-5 h-5 left-img">
            <i
              v-if="item.name === 'Billing'"
              class="fas fa-file-invoice text-22px "
            ></i>
            <i
              v-else-if="item.name === 'Payment'"
              class="fas fa-credit-card text-20px "
            ></i>

            <i
              v-else-if="item.name === 'Bank'"
              class="fas fa-building  text-20px"
            ></i>
            <img v-else :src="icon(item.icon)" />
          </div>
          <div class="right-content">
            <p class="text-sm font-bold truncate">{{ item.name }}</p>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data: () => ({
    items: [
      {
        name: 'Profile',
        icon: 'operator',
        link: 'profile',
      },
      {
        name: 'Payment',
        icon: 'payment',
        link: 'payment',
      },
      {
        name: 'Billing',
        icon: 'support',
        link: 'billing',
      },
      {
        name: 'Bank',
        icon: 'support',
        link: 'bank',
      },
    ],
  }),

  methods: {
    icon(icon) {
      return require(`@/assets/icon/settings/${icon}-black.svg`)
    },
  },
}
</script>

<style lang="scss" scoped>
.router-link-active {
  background-color: #256baa;
  color: #fff;
}
</style>
